<!--
 * @Description: 
 * @Author: 周强
 * @Date: 2021-12-16 15:09:55
 * @LastEditTime: 2022-01-04 13:36:04
 * @LastEditors:  
-->
<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <router-view></router-view>
  </div>
</template>

<script>
import './assets/css/common.css'
export default {
  name: 'App',
  components: {
    
  },
  watch: {
    '$route.meta.title': function(val) {
      document.title = val;
    }
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* #app {
  width: 200px;
  height: 200px;
  background: red;
} */
</style>
