// 导入axios
import axios from 'axios'
import qs from 'qs'
const Config = {
  GJ: process.env.VUE_APP_BASE_URL + '/api',
  GPS: process.env.VUE_APP_GPS_BASE_URL + '/api',
  OA: process.env.VUE_APP_OA_BASE_URL + '/api',
  CREDIT: process.env.VUE_APP_CREDIT_BASE_URL + '/api'
}
// 进行一些全局配置
// 公共路由(网络请求地址)
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL + '/api';
// 请求响应超时时间
axios.defaults.timeout = 50000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers.common.Authorization = 'Bearer d3d3Lmp1cnVpeXVuLmNvbQ=='

// 实例对象
// let instance = axios.create({
//   timeout: 3000,
//   headers: {
//     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
//   }
// })

// 请求拦截器
axios.defaults.transformRequest = [
  function (data, config) {
    const contentType = config['Content-Type']
    if (!contentType) return qs.stringify(data)
    if (contentType.toLowerCase() === 'application/json;charset=utf-8') {
      return JSON.stringify(data)
    } else if (contentType.toLowerCase() === 'application/x-www-form-urlencoded;charset=utf-8') {
      return qs.stringify(data)
    }
    return data
  }
]

// 封装自己的get/post方法
export default {
  getRequestBaseUrl: url => {
    const paths = url.split('/')
    if (paths[1] === 'cloud') {
      axios.defaults.baseURL = Config.GPS
    } else if (paths[1] === 'oa') {
      axios.defaults.baseURL = Config.OA
      axios.defaults.headers['Admin-Token'] = 'a4f4ec6665c141d3b6d63beedd08a4db'
    } else if (paths[1] === 'credit') {
      axios.defaults.baseURL = Config.CREDIT
      axios.defaults.headers.common.Authorization = ''
    } else {
      axios.defaults.baseURL = Config.GJ
      // axios.defaults.headers.common.Authorization = "";
      delete axios.defaults.headers.common.Authorization
    }
  },
  get: function (path = '', data = {}) {
    const _this = this
    return new Promise((resolve, reject) => {
      _this.getRequestBaseUrl(path)
      axios
        .get(path, {
          params: data
        })
        .then(function (response) {
          resolve(response.data)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  post: function (path = '', data = {}, config) {
    const _this = this
    return new Promise((resolve, reject) => {
      _this.getRequestBaseUrl(path)
      return axios({
        method: 'post',
        url: path,
        headers: config || {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        data: data
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
}
