import Vue from 'vue'
import Vuex from 'vuex'


import map from './map'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    map
  },
  state: {

  },
  mutations: {

  },
  actions: {

  }
})
