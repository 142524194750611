import { getTracks } from '@/api/device'
const map = {
  namespaced: true,
  state: {
    tracks: []
  },
  mutations: {
    setTracks: (state, tracks) => {
      state.tracks = tracks
    }
  },
  getters: {
    tracks: ({ tracks }) => tracks
  },
  actions: {
    async getTracks({ commit }, parameter) {
      const res = await getTracks(parameter)
      let data = res?.data ?? []
      commit('setTracks', data)
      return res
    }
  }
}
export default map
