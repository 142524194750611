import request from '@/utils/request'

const deviceapi = {
  deviceStatus: '/cloud/gps/monitor', // 设备状态
  getTracks: '/cloud/track/getTracks' // 轨迹回放
}

/**
 * 提交支付
 * @param {*} parameter
 */
export function getDeviceInfo (parameter) {
  return request.get(deviceapi.deviceStatus,parameter)
}
// 轨迹数据
export function getTracks (parameter) {
  return request.get(deviceapi.getTracks,parameter)
}
// /**
//  * 获取保单
//  * @param {*} parameter
//  */
//  export function getProductList (parameter) {
//   return request.get(payapi.productList,parameter)
// }
